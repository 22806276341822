<template>

  <div class="component-inner"><!--max-w-screen-2xl mx-auto-->
    <div
    class="jumbotron-item component-child" :class="[item.acf_fc_layout]" v-for="(item, index) in computedList" :key="index">
    <!-- flex flex-col lg:flex-row flex-wrap mb-16 last-of-type:mb-0-->

      <header
      v-if="item.options && item.options.title"
      class="component-header"><!-- flex-1 xl:flex-none lg:w-1/2 lg:pr-12-->
        <h2>{{ item.options.title }}</h2>
      </header>

      <div class="text-column"><!-- flex-1 lg:flex-none lg:max-w-prose w-full lg:w-1/2-->

        <component
        :is="item.acf_fc_layout"
        :block="item"
        :data="item.accordion ? item.accordion.accordion_items : false"
        :key="index" />

      </div>

    </div>

    <button v-if="showLoadMore" class="flex items-center gap-4 text-brand-green font-SuisseIntlRegular text-xl mx-auto mt-16" @click="loadMore">
      <span>{{ moreLabel }}</span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" aria-hidden="true" class="flex-none hidden lg:block h-4 w-auto fill-current"><path d="M374.6 310.6l-160 160C208.4 476.9 200.2 480 192 480s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 370.8V64c0-17.69 14.33-31.1 31.1-31.1S224 46.31 224 64v306.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0S387.1 298.1 374.6 310.6z"></path></svg>
    </button>

  </div>

</template>

<script>
import Accordion from './../Accordion/Accordion'
import Feature from '@/component/Page/Feature'
import Paragraph from './Paragraph'
import Preamble from './Preamble'
import InternalButton from './InternalButton'

export default {
  props: {
    block: Object
  },
  components: {
    Accordion,
    Paragraph,
    Preamble,
    Feature,
    InternalButton
  },
  data : () => ({
    showLoadMore: false,
    moreLabel: 'Les mer',
    items: null,
  }),
  methods: {
    loadMore() {
      this.items = this.$props.block.blocks.length
    }
  },
  computed: {
    computedList() {

      let listItems = []
      let totalItems = []

      if ( this.$props.block.blocks.length > 0 ) {
        totalItems.push(...this.$props.block.blocks)

        if ( this.$props.block.options.loadMore && this.items < this.$props.block.options.moreOptions.items ) {
          this.items = this.$props.block.options.moreOptions.items
          this.moreLabel = this.$props.block.options.moreOptions.label ? this.$props.block.options.moreOptions.label : 'Les mer'

        } else if (this.items < this.$props.block.blocks.length ) {
          this.items = this.$props.block.blocks.length
        }

        listItems.push(...totalItems.slice(0, this.items))
        this.showLoadMore = listItems.length < totalItems.length ? true : false

        // console.log("totalItems", totalItems);

        return listItems
      }
    },
  },
}
</script>
