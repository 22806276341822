<template>
  <div :class="`path -path_${path}`">
    <svg v-if="path === 0" aria-hidden="true" :class="`graphic -mobile`" version="1.1" viewBox="0 0 142.9 392.4" >
      <path fill="none" stroke="#0A3640" stroke-dasharray="10" d="M0.1,391.9c201.4-48.2,166-355.3,36-391.4"/>
    </svg>
    <svg v-if="path === 0" aria-hidden="true" :class="`graphic -desktop`" version="1.1" viewBox="0 0 503 372.8">
      <path fill="none" stroke="#0A3640" stroke-dasharray="30" d="M502.9,12.7C192.8-66.4-24,259.6,2.7,372.7"/>
    </svg>

    <svg v-if="path === 1" aria-hidden="true" :class="`graphic -mobile`" version="1.1" viewBox="0 0 134 304.3" >
      <path fill="none" stroke="#0A3640" stroke-dasharray="10" d="M62,0.4c-125,122.5-38.1,297.7,72,303.4"/>
    </svg>
    <svg v-if="path === 1" aria-hidden="true" :class="`graphic -desktop`" version="1.1" viewBox="0 0 461.6 353.4">
      <path fill="none" stroke="#0A3640" stroke-dasharray="30" d="M461.6,352.9C34.8,347,0.5,0,0.5,0"/>
    </svg>

    <svg v-if="path === 2" aria-hidden="true" :class="`graphic -mobile`" version="1.1" viewBox="0 0 142.9 392.4" >
      <path fill="none" stroke="#0A3640" stroke-dasharray="10" d="M0.1,391.9c201.4-48.2,166-355.3,36-391.4"/>
    </svg>
    <svg v-if="path === 2" aria-hidden="true" :class="`graphic -desktop`" version="1.1" viewBox="0 0 580.2 320.7">
    <path fill="none" stroke="#0A3640" stroke-dasharray="30" d="M579.7,0.2C486.1,285.7,0,320.2,0,320.2"/>
    </svg>

  </div>
</template>
<script>
  export default {
    props: {
      path: Number
    }
  }
</script>
