<template>

    <div class="component-inner">
        <div class="component-header">
            <h2 v-if="block.heading">{{ block.heading }}</h2>
            <p v-if="block.ingress">{{ block.ingress }}</p>
        </div>

        <div class="graphic-grid">
            <!---<div v-for="(item, index) in block.grid.items" class="item {{ index }}">
                <div v-if="item.icon" class="" v-html="item.icon" />
                <h3 v-if="item.heading">{{ item.heading }}</h3>
                <p v-if="item.text">{{ item.text }}</p>
            </div>-->
            <div class="item one">
                <svg id="arrow-one" xmlns="http://www.w3.org/2000/svg" width="194.874" height="33.022" viewBox="0 0 194.874 33.022">
                    <g transform="translate(-459.526 -1677.795)">
                        <line x2="183" transform="translate(460.526 1694.309)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2" stroke-dasharray="20"/>
                        <path d="M-10563.82-6600.054l14.794,14.821-14.794,15.373" transform="translate(11202.026 8279.264)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2"/>
                    </g>
                </svg>

                <div v-if="block.grid_one.icon" class="" v-html="block.grid_one.icon" />
                <h3 v-if="block.grid_one.heading" class="heading">{{ block.grid_one.heading }}</h3>
                <p v-if="block.grid_one.text" class="text">{{ block.grid_one.text }}</p>
            </div>

            <div class="item two">
                <svg id="arrow-three" xmlns="http://www.w3.org/2000/svg" width="606.095" height="125.126" viewBox="0 0 606.095 125.126">
                    <g transform="translate(-866.531 -1539.998)">
                        <path d="M-10563.82-6600.054l14.794,14.821-14.794,15.373" transform="translate(8242.12 12025.643) rotate(25)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2"/>
                        <path d="M-10329.055-6585.6s236.579-228.115,600.245-62.3" transform="translate(11197 8249.307)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2" stroke-dasharray="20"/>
                    </g>
                </svg>


                <svg id="arrow-two" xmlns="http://www.w3.org/2000/svg" width="187.874" height="33.022" viewBox="0 0 187.874 33.022">
                    <g transform="translate(-842 -1677.795)">
                        <line x2="144" transform="translate(875 1694.309)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2" stroke-dasharray="20"/>
                        <path d="M-10563.82-6600.054l14.794,14.821-14.794,15.373" transform="translate(11577.5 8279.264)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2"/>
                        <circle cx="9" cy="9" r="9" transform="translate(842 1685.309)" fill="#093640"/>
                    </g>
                </svg>

                <h3 class="heading">Tilfeldig trekning</h3>
                <p class="text">Gravide som fyller kriterier for deltakelse vil fordeles i to grupper gjennom en tilfeldig trekning. Den gravide har like stor sjanse for å havne i hver av gruppene. </p>
            </div>

            <div class="item three">
                <h3 class="heading">Strukturert oppfølging</h3>
                <p class="text">Den ene gruppen vil få tilbud om oppfølging gjennom Sammen på vei. De vil bli kjent med familiesykepleier og sammen legge opp plan for programmet. </p>
                <svg id="arrow-four" xmlns="http://www.w3.org/2000/svg" width="33.022" height="77.901" viewBox="0 0 33.022 77.901">
                    <g transform="translate(-1151.989 -2035.5)">
                        <line x1="0.001" y2="63" transform="translate(1168.498 2036.5)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2" stroke-dasharray="20"/>
                        <path d="M-10563.82-6600.054l14.794,14.821-14.794,15.373" transform="translate(-5416.457 12661.027) rotate(90)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2"/>
                    </g>
                </svg>

            </div>

            <div class="item four">
                <h3 class="heading">Kontrollgruppe</h3>
                <p class="text">Den andre gruppen vil få en veiledningssamtale med en jordmor/helsesykepleier og videre et ordinært helsetilbud for gravide.</p>
                <svg id="arrow-five" xmlns="http://www.w3.org/2000/svg" width="206.701" height="260.19" viewBox="0 0 206.701 260.19">
                    <g transform="translate(-1385.202 -2026.321)">
                        <path d="M-10563.82-6600.054l14.794,14.821-14.794,15.373" transform="translate(-9162.424 -4314.957) rotate(180)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2"/>
                        <path d="M73.794-7492.472s191.036-13.342,192.309-242.674" transform="translate(1324.794 9762.473)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2" stroke-dasharray="20"/>
                    </g>
                </svg>

            </div>

            <div class="item last">
                <div></div>
                <div class="content">
                    <h3 class="heading">Forbedret helsehjelp</h3>
                    <p class="text">Når studien er ferdig vil innsamlet data fra deltakere i begge grupper  kunne gi svar på hvilken helsehjelp førstegangsforeldre bør få i fremtiden.</p>
                    <a href="">Les mer om studien</a>
                </div>
                <!---<div v-if="gridbox.icon" class="" v-html="gridbox.icon" />
                <div>
                    <h3 v-if="gridbox.heading">{{ gridbox.heading }}</h3>
                    <p v-if="gridbox.text">{{ gridbox.text }}</p>
             
                    <div class="hero-link" v-if="gridbox.link">
                        <a 
                        :href="gridbox.link.url" 
                        :target="gridbox.link.target || '_self'" 
                        rel="noopener noreferrer" 
                        class="link-button">
                        {{ gridbox.link.title }}
                        <svg class="flex-none h-4 w-auto fill-current px-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"/></svg>
                        </a>
                    </div>
                </div>-->
            </div>

        </div>
    </div>
  
  </template>
  
  <script>
  export default {
    name: 'InfoGraphic',
    props: {
      block: Object,
    },
  }
  </script>
  